<template>
  <div class="notice">
    <header-bar :showNotice="false" :showBack="true" :transparent="true"></header-bar>

    <div style="margin-top: -100px;">
      <title-name :title="$t('Notice.title')" label="Notice" :showImg="false"></title-name>
    </div>

    <div class="list">
      <div class="item-block" v-for="item in list" :key="item.id" @click="tolink('NoticeDetails?id=' + item.id)">
        <div class="item-title">
          <div>{{ item.newstitle }}</div>
          <img src="@/assets/img/user/link4.png" />
        </div>
        <div class="item-date">{{ item.newstime }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBar from '@/components/HeaderBar'
import TitleName from '@/components/TitleName'
import { ref } from 'vue'

export default {
  name: '',
  components: {
    HeaderBar,
    TitleName
  },
  setup() {
    return {
      list: ref([])
    }
  },
  created() {
    this.getNews()
  },
  methods: {
    tolink(path) {
      this.$router.push(path)
    },
    getNews() {
      let _this = this
      _this.$request.post(
        "api/News/List",
        {},
        (res) => {
          if(res.data.code == 0) {
            _this.$q.dialog({
							message: res.data.msg,
						});
          }
          let data = res.data.data
          _this.list = data
        }
      )
    }
  }
}
</script>

<style scoped>
.notice {
  min-height: 100vh;
  background-image: linear-gradient(to right top, #2d8998, #519bab, #6fadbe, #8cc0d1, #a8d3e4, #b1dbec, #bbe2f3, #c4eafb, #bde8fa, #b7e5fa, #b0e3f9, #a9e1f8);
}

.list {
  padding: 10px;
}

.item-block {
  padding: 15px;
  margin-bottom: 14px;
  border-radius: 10px;
  background-color: #fff;
}

.item-title {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
}

.item-title img {
  width: 16px;
  height: 16px;
  margin-left: 10px;
}

.item-date {
  font-size: 12px;
  color: #615e5f;
}
</style>